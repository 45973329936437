<template>
    <path d="m496 336h-16v-48c0-112.8-83.5-205.9-192-221.5v-18.5h48c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16h-160c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h48v18.5c-108.5 15.6-192 108.7-192 221.5v48h-16c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16zm-64 0h-352v-48c0-97.1 78.9-176 176-176s176 78.9 176 176v48z"/>
</template>

<script>
export default {
    setup(props, { emit }) {
        emit('dimensions', { width: 512, height: 384 });
    },
    emits: ['dimensions'],
};
</script>
